/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

require(`./src/styles/global.css`);
// const React = require(`react`);
// const { Provider } = require(`react-redux`);
// const store = require(`./src/app/store`);

// exports.wrapRootElement = ({ element }) => {
//   return <Provider store={store}>{element}</Provider>;
// };
